








































































































































































































import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import CouponInput from '~/components/CouponInput.vue';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import { useAddToCart } from '~/helpers/cart/addToCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useCart } from '~/modules/checkout/composables/useCart';
import CartProduct from './cart/CartProduct.vue';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    CouponInput,
    CartProduct,
  },
  props: {
    isCheckout: {
      type: Boolean,
      default: false,
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
    hideCheckoutButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const cartView = useCartView();
    const { cart, removeCoupon, removeItem } = useCart();
    const { addItemToCart } = useAddToCart();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const router = useRouter();
    const { app } = useContext();

    const cartIsLoading = ref(false);

    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    const removeDiscount = async () => {
      const operationPromise = removeCoupon({});
      await operationPromise;
    };

    const couponCodeAppliedToCart = computed(
      () => cartGetters.getAppliedCoupon(cart.value)?.code,
    );

    const shippingMessage = computed(() => {
      const resetAmount = 0;
      return resetAmount
        ? `You are $${resetAmount} away from free shipping.`
        : null;
    });

    const estimatedSalesTax = computed(() => Number(cart.value.prices.subtotal_including_tax.value) - Number(cart.value.prices.subtotal_excluding_tax.value));

    const calculatedEstimatedSalesTax = computed(() => cartGetters.getTaxAmount(cart.value));

    const shippingAmount = computed(() => (cart.value.shipping_addresses.length > 0
      ? (cart.value.shipping_addresses[0].selected_shipping_method
        ? cart.value.shipping_addresses[0].selected_shipping_method.amount
          .value
        : 0)
      : 0));

    const removeCartItem = async (item: any) => {
      cartIsLoading.value = true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      await removeItem(item);
      cartIsLoading.value = false;
      cartView.itemToRemove.value = item.product;
      if (props.isCheckout && cartView.totalItems.value === 0) {
        const anchor = '#categories-section';
        const pageroute = app.localeRoute({ name: 'home' });
        const pathedRoute = { ...pageroute };
        pathedRoute.hash = anchor;
        await router.push(pathedRoute);
      }
    };

    const addCartItem = async (item: any) => {
      cartIsLoading.value = true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      await addItemToCart(item);
      cartIsLoading.value = false;
      cartView.itemToRemove.value = null;
    };

    return {
      calculatedEstimatedSalesTax,
      cartIsLoading,
      addCartItem,
      removeCartItem,
      couponCodeAppliedToCart,
      removeDiscount,
      shippingMessage,
      ...cartView,
      isCartSidebarOpen,
      toggleCartSidebar,
      goToCart,
      estimatedSalesTax,
      shippingAmount,
    };
  },
});
